import { comingSoon } from "../utils/toast";

export default function SolanaWallet() {
  return (
    <>
      <img className="modal-icon" src="/images/solana-pixel.png" />

      <p className="modal-title">Solana Wallet</p>

      <p className="modal-subtitle">
        Add your Solana wallet address to receive rewards.
      </p>

      <input
        type="text"
        placeholder="Enter your Solana wallet address"
        className="form-input"
      />

      <button className="btn-get" onClick={comingSoon}>
        Save
      </button>
    </>
  );
}
