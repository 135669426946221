export const leagues = [
  {
    id: 1,
    name: "Novice",
    bg: "/images/league/novice-bg.png",
    icon: "/images/league/novice.png",
    reward: 0,
    start: 0,
    end: 4999,
  },
  {
    id: 2,
    name: "Warrior",
    bg: "/images/league/warrior-bg.png",
    icon: "/images/league/warrior.png",
    reward: 500,
    start: 5000,
    end: 49999,
  },
  {
    id: 3,
    name: "Elite",
    bg: "/images/league/elite-bg.png",
    icon: "/images/league/elite.png",
    reward: 5000,
    start: 50000,
    end: 99999,
  },
  {
    id: 4,
    name: "Master",
    bg: "/images/league/master-bg.png",
    icon: "/images/league/master.png",
    reward: 10000,
    start: 100000,
    end: 499999,
  },
  {
    id: 5,
    name: "Grandmaster",
    bg: "/images/league/grandmaster-bg.png",
    icon: "/images/league/grandmaster.png",
    reward: 50000,
    start: 500000,
    end: 999999,
  },
  {
    id: 6,
    name: "Epic",
    bg: "/images/league/epic-bg.png",
    icon: "/images/league/epic.png",
    reward: 100000,
    start: 1000000,
    end: 4999999,
  },
  {
    id: 7,
    name: "Legend",
    bg: "/images/league/legend-bg.png",
    icon: "/images/league/legend.png",
    reward: 500000,
    start: 5000000,
    end: 9999999,
  },
  {
    id: 8,
    name: "Mythic",
    bg: "/images/league/mythic-bg.png",
    icon: "/images/league/mythic.png",
    reward: 1000000,
    start: 10000000,
    end: 49999999,
  },
  {
    id: 9,
    name: "Supreme",
    bg: "/images/league/supreme-bg.png",
    icon: "/images/league/supreme.png",
    reward: 5000000,
    start: 50000000,
    end: Infinity,
  },
];

export const getCurrentLeagueIndex = (score) => {
  return leagues.findIndex(
    (league) => score >= league.start && score < league.end
  );
};
