import { toast } from "react-hot-toast";

export const comingSoon = () => {
  toast("Coming Soon", {
    duration: 800,
    position: "top-center",
    style: {
      borderRadius: "10px",
      background: "#fff",
      color: "#000",
      border: "2px solid #000",
    },
  });
};
