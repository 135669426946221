/* eslint-disable react-hooks/exhaustive-deps */
import { energyAtom, energyLimitAtom, lastEnergyAtom } from "../atoms/energy";

import { BarLoader } from "react-spinners";
import Desktop from "../components/Desktop";
import Layout from "../components/Layout";
import { isMobile } from "../utils/detect";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";

function Root() {
  const setEnergy = useSetAtom(energyAtom);
  const setLastUpdatedEnergy = useSetAtom(lastEnergyAtom);
  const navigate = useNavigate();
  const energyLimit = 500;
  const setEnergyLimit = useSetAtom(energyLimitAtom);

  useEffect(() => {
    setEnergyLimit(energyLimit);

    const updateLatestEnergy = () => {
      const currentTime = new Date().getTime();
      const lastUpdated = parseInt(
        JSON.parse(localStorage.getItem("lastUpdatedEnergy") || "0")
      );
      const diffInSeconds = (currentTime - lastUpdated) / 1000;

      if (diffInSeconds > 0) {
        const energyToAdd = Math.floor(Math.round(diffInSeconds));

        const prevEnergy = parseInt(
          JSON.parse(localStorage.getItem("energy") || "0")
        );
        const updatedEnergy = Math.min(prevEnergy + energyToAdd, energyLimit);

        if (energyToAdd > 0) {
          setEnergy(updatedEnergy);
          setLastUpdatedEnergy(currentTime.toString());
        }
      }

      setTimeout(() => {
        navigate("/tap");
      }, 2000);
    };

    if (isMobile) {
      updateLatestEnergy();
    }
  }, []);

  if (!isMobile) {
    return <Desktop />;
  }

  return (
    <Layout back={false}>
      <div className="bg-gradient"></div>
      <div className="loader-root">
        <BarLoader color="#fe6f30" />.
        <p style={{ zIndex: 3 }}>
          Welcome to Game Summer! We are still polishing our MVP. Feel free to
          drop feedback on Telegram.
        </p>
      </div>
    </Layout>
  );
}

export default Root;
