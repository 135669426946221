export const formatNumberCompact = (num) => {
  if (num >= 1000000) {
    return num / 1000000 + "M";
  } else if (num >= 1000) {
    return num / 1000 + "K";
  } else {
    return num.toString();
  }
};

export const formatWithCommas = (num) => {
  return num.toLocaleString();
};

export const vhToPx = (vh) => {
  return (window.innerHeight * vh) / 100;
};
