import { BackButton } from "@twa-dev/sdk/react";
import PropTypes from "prop-types";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useScrollLock from "../hooks/useScrollLock";

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  back: PropTypes.bool,
};

export default function Layout({ children, back = true }) {
  const scrollableRef = useScrollLock();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div ref={scrollableRef} className="root-page">
      {back && <BackButton onClick={goBack} />}

      <Toaster position="bottom-center" />

      {children}
    </div>
  );
}
