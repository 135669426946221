import { comingSoon } from "../../utils/toast";

export default function RechargingSpeed() {
  return (
    <>
      <img className="modal-icon" src="/images/energy-icon.png" />

      <p className="modal-title">Recharging Speed</p>

      <p className="modal-subtitle">
        Increase your energy recharge speed.
        <br />
        +1 energy per second, per level.
      </p>

      <p className="modal-charge">
        <img src="/dollar.png" alt="Coins" />
        500 - Level 1
      </p>

      <button className="btn-get" onClick={comingSoon}>
        Get
      </button>
    </>
  );
}
