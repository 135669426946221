export const isMobile =
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ||
  (/Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1);

export const getPhoneType = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPhone/.test(userAgent) && !window.MSStream) {
    return "iPhone";
  } else if (/android/i.test(userAgent)) {
    return "Android";
  }

  return "Unknown";
};
