import { apiKey, apiUrl } from "../config";
import { useEffect, useState } from "react";

import BigLoader from "../components/BigLoader";
import Layout from "../components/Layout";

export default function Stats() {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({ totalUsers: 0, totalScore: 0 });

  useEffect(() => {
    const fetchStats = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(apiUrl + "stats", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
          },
        });
        const { success, data } = await response.json();
        console.log("Data", data);
        if (success) {
          setStats(data);
        } else {
          console.error("Failed to fetch stats");
        }
      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (isLoading) {
    return <BigLoader />;
  }

  return (
    <Layout>
      <div className="page">
        <div className="page-title">Stats</div>
        <div className="bg-gradient-small"></div>

        <div className="booster">
          <img src="/images/one-finger.png" alt="Coin Icon" />

          <div className="booster-right">
            <p className="booster-title">Total Taps</p>
            <p className="booster-subtitle">
              {stats && stats.totalScore
                ? stats.totalScore.toLocaleString()
                : "Loading..."}
            </p>
          </div>
        </div>

        <div className="booster">
          <img src="/images/users.png" />

          <div className="booster-right">
            <p className="booster-title">Total Players</p>
            <p className="booster-subtitle">
              {stats && stats.totalUsers
                ? stats.totalUsers.toLocaleString()
                : "Loading..."}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
