import { apiKey, apiUrl } from "../config";
import { useEffect, useState } from "react";

import BigLoader from "../components/BigLoader";
import Layout from "../components/Layout";
import WebApp from "@twa-dev/sdk";
import { formatNumberCompact } from "../utils/number";
import { getDefaultUser } from "../utils/user";
import { getPhoneType } from "../utils/detect";

export default function Invite() {
  const phone = getPhoneType();
  const [referrals, setReferrals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const openTelegramLink = (url) => {
    if (phone === "iPhone") {
      window.location.replace(url);
    } else if (WebApp.openTelegramLink) {
      WebApp.openTelegramLink(url);
    } else {
      window.open(url);
    }
  };

  const openInvite = () => {
    openTelegramLink("https://t.me/SolanaSummerBot?start=invite");

    WebApp.close();
  };

  const getAvatarText = (name, username) => {
    const text = name || username || "";
    let initials = text
      .toUpperCase()
      .split(/\s+/)
      .map((word) => word[0])
      .join("")
      .substring(0, 2);

    return initials || "NA";
  };

  useEffect(() => {
    const fetchReferrals = async () => {
      setIsLoading(false);
      const user = WebApp.initDataUnsafe.user || getDefaultUser();

      try {
        const response = await fetch(apiUrl + "referrals", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": apiKey,
          },
          body: JSON.stringify({ telegramId: user.id }),
        });
        const data = await response.json();
        if (data.success) {
          setReferrals(data.data);
          setIsLoading(false);
        } else {
          console.error("Failed to fetch referrals:", data.error);
        }
      } catch (error) {
        console.error("Error fetching referrals:", error);
      }
    };

    fetchReferrals();
  }, []);

  if (isLoading) {
    return <BigLoader />;
  }

  return (
    <Layout>
      <div className="page">
        <div className="bg-gradient-small"></div>

        <h1 className="page-title">{referrals.length} Referrals</h1>

        <p className="page-subtitle-main">+0</p>

        <div className="card">
          <p className="text-white">
            Invite your friends and both you and your friends can earn
            <span> 3,000 </span>
            coins each! Additionally, you can earn extra <span>
              bonuses
            </span>{" "}
            for every level your friends achieve.
          </p>
        </div>

        <p className="card-title">Referrals List</p>

        {referrals.length === 0 ? (
          <div className="card">
            <p className="text-white text-center">No referrals yet</p>
          </div>
        ) : (
          referrals.map((referral, index) => (
            <div key={index} className="referral">
              <div className="referral-photo">
                {getAvatarText(referral.name, referral.username)}
              </div>
              <div className="referral-info">
                <p className="referral-name">{referral.name || "Unknown"}</p>
                <div className="referral-info-bottom">
                  <p className="referral-league">
                    <img
                      src={`/images/league/${referral.league.toLowerCase()}.png`}
                      alt="Level"
                    />
                    {referral.league}
                  </p>
                  <p className="referral-dot">&#x2022;</p>
                  <p className="referral-score">
                    <img src="/dollar.png" alt="Coins" />
                    {formatNumberCompact(referral.score)}
                  </p>
                </div>
              </div>
              <p className="referral-point">+0</p>
            </div>
          ))
        )}

        <button className="btn-invite" onClick={() => openInvite()}>
          Invite a friends
        </button>
      </div>
    </Layout>
  );
}
