import Layout from "../components/Layout";
import Modal from "../components/Modal";
import SolanaWallet from "../components/SolanaWallet";
import { comingSoon } from "../utils/toast";
import { useState } from "react";

export default function Tasks() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const invites = [
    {
      id: 1,
      name: "Invite 1 Friend",
      reward: 10000,
      icon: "/images/users.png",
    },
    {
      id: 2,
      name: "Invite 3 Friends",
      reward: 50000,
      icon: "/images/users.png",
    },
    {
      id: 3,
      name: "Invite 10 Friends",
      reward: 200000,
      icon: "/images/users.png",
    },
    {
      id: 4,
      name: "Invite 25 Friends",
      reward: 250000,
      icon: "/images/users.png",
    },
    {
      id: 5,
      name: "Invite 50 Friends",
      reward: 300000,
      icon: "/images/users.png",
    },
    {
      id: 6,
      name: "Invite 100 Friends",
      reward: 500000,
      icon: "/images/users.png",
    },
    {
      id: 7,
      name: "Invite 500 Friends",
      reward: 2000000,
      icon: "/images/users.png",
    },
    {
      id: 8,
      name: "Invite 1000 Friends",
      reward: 3500000,
      icon: "/images/users.png",
    },
  ];

  return (
    <Layout>
      <div className="page">
        <div className="bg-gradient-small"></div>

        <div className="page-coin-container">
          <img src="/dollar.png" className="page-coin" />
          <h1 className="page-title">Earn more coins</h1>
        </div>

        <p className="card-title">Quick Start</p>
        <div className="booster">
          <img src="/images/x-icon.png" />
          <div className="booster-right">
            <p className="booster-title">Follow Us on X</p>
            <p className="booster-subtitle">
              <img src="/dollar.png" alt="Coins" />
              <span>50,000</span>
            </p>
          </div>
        </div>
        <div className="booster">
          <img src="/images/telegram-icon.png" />
          <div className="booster-right">
            <p className="booster-title">Join Our Telegram Group</p>
            <p className="booster-subtitle">
              <img src="/dollar.png" alt="Coins" />
              <span>50,000</span>
            </p>
          </div>
        </div>
        <div className="booster" onClick={() => setIsModalOpen(true)}>
          <img src="/images/solana-pixel.png" />
          <div className="booster-right">
            <p className="booster-title">Add Your Solana Address</p>
            <p className="booster-subtitle">
              <img src="/dollar.png" alt="Coins" />
              <span>100,000</span>
            </p>
          </div>
        </div>

        <p className="card-title">Invite</p>

        {invites.map((invite) => (
          <div key={invite.id} className="booster">
            <img src={invite.icon} />
            <div className="booster-right">
              <p className="booster-title">{invite.name}</p>
              <p className="booster-subtitle">
                <img src="/dollar.png" alt="Coins" />
                <span>{invite.reward.toLocaleString()}</span>
              </p>
            </div>
            <button className="btn-claim" onClick={comingSoon}>
              Claim
            </button>
          </div>
        ))}
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <SolanaWallet />
      </Modal>
    </Layout>
  );
}
