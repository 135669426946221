import { useEffect, useRef } from "react";

/**
 * A custom hook that locks scroll on the body of the page but allows scrolling on a specified element.
 */
const useScrollLock = () => {
  const scrollableRef = useRef(null);

  useEffect(() => {
    const originalBodyStyle = document.body.style.cssText;
    const originalHtmlStyle = document.documentElement.style.cssText;

    document.documentElement.style.height = "auto";
    document.documentElement.style.overflow = "hidden";
    document.body.style.minHeight = "100vh";
    document.body.style.height = "100vh";
    document.body.style.overflowY = "hidden";

    const overflow = 100;
    document.body.style.marginTop = `${overflow}px`;
    document.body.style.height = `${window.innerHeight + overflow}px`;
    document.body.style.paddingBottom = `${overflow}px`;
    window.scrollTo(0, overflow);

    let touchStartY = 0;
    const onTouchStart = (e) => {
      touchStartY = e.touches[0].clientY;
    };

    const onTouchMove = (e) => {
      if (!scrollableRef.current) {
        e.preventDefault();
        return;
      }

      const currentY = e.changedTouches[0].clientY;
      const isScrollingUp = touchStartY < currentY;
      const scrollTop = scrollableRef.current.scrollTop;

      if (
        (scrollTop <= 0 && isScrollingUp) ||
        (scrollTop + scrollableRef.current.offsetHeight >=
          scrollableRef.current.scrollHeight &&
          !isScrollingUp)
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchstart", onTouchStart, { passive: false });
    document.addEventListener("touchmove", onTouchMove, { passive: false });

    return () => {
      document.body.style.cssText = originalBodyStyle;
      document.documentElement.style.cssText = originalHtmlStyle;

      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchmove", onTouchMove);
    };
  }, []);

  return scrollableRef;
};

export default useScrollLock;
