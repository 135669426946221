import EnergyLimit from "../components/boosters/EnergyLimit";
import FullEnergy from "../components/boosters/FullEnergy";
import Layout from "../components/Layout";
import MasterTap from "../components/boosters/MasterTap";
import Modal from "../components/Modal";
import Multitap from "../components/boosters/Multitap";
import RechargingSpeed from "../components/boosters/RechargingSpeed";
import TapBot from "../components/boosters/TapBot";
import { scoreAtom } from "../atoms/score";
import { useAtomValue } from "jotai";
import { useState } from "react";

export default function Boosters() {
  const score = useAtomValue(scoreAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBooster, setSelectedBooster] = useState(null);

  const boostersComponents = {
    MasterTap: <MasterTap />,
    FullEnergy: <FullEnergy />,
    Multitap: <Multitap />,
    EnergyLimit: <EnergyLimit />,
    RechargingSpeed: <RechargingSpeed />,
    TapBot: <TapBot />,
  };

  const openModal = (booster) => {
    setSelectedBooster(booster);
    setIsModalOpen(true);
  };

  return (
    <Layout>
      <div className="page">
        <div className="bg-gradient-small"></div>

        <div className="page-subtitle">Your Balance</div>
        <div className="booster-result">
          <img src="/dollar.png" />

          <p>{score.toLocaleString()}</p>
        </div>

        <p className="card-title">Your daily boosters</p>

        <div className="booster" onClick={() => openModal("MasterTap")}>
          <img src="/images/master-tap-icon.png" />
          <div className="booster-right">
            <p className="booster-title">MasterTap</p>
            <p className="booster-subtitle">3/3 available</p>
          </div>
        </div>

        <div className="booster" onClick={() => openModal("FullEnergy")}>
          <img src="/images/energy-icon.png" />
          <div className="booster-right">
            <p className="booster-title">Full Energy</p>
            <p className="booster-subtitle">3/3 available</p>
          </div>
        </div>

        <p className="card-title card-gap-xl">Boosters</p>

        <div className="booster" onClick={() => openModal("Multitap")}>
          <img src="/images/multitap-icon.png" />
          <div className="booster-right">
            <p className="booster-title">Multitap</p>
            <p className="booster-subtitle">
              <img src="/dollar.png" alt="Coins" />
              <span>500</span> - Level 1
            </p>
          </div>
        </div>

        <div className="booster" onClick={() => openModal("EnergyLimit")}>
          <img src="/images/battery-icon.png" />
          <div className="booster-right">
            <p className="booster-title">Energy Limit</p>
            <p className="booster-subtitle">
              <img src="/dollar.png" alt="Coins" />
              <span>500</span> - Level 1
            </p>
          </div>
        </div>

        <div className="booster" onClick={() => openModal("RechargingSpeed")}>
          <img src="/images/energy-icon.png" />
          <div className="booster-right">
            <p className="booster-title">Recharging Speed</p>
            <p className="booster-subtitle">
              <img src="/dollar.png" alt="Coins" />
              <span>500</span> - Level 1
            </p>
          </div>
        </div>

        <div className="booster" onClick={() => openModal("TapBot")}>
          <img src="/images/robot-icon.png" />
          <div className="booster-right">
            <p className="booster-title">TapBot</p>
            <p className="booster-subtitle">
              <img src="/dollar.png" alt="Coins" />
              <span>200,000</span> - Works while you rest!
            </p>
          </div>
        </div>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {boostersComponents[selectedBooster]}
        </Modal>
      </div>
    </Layout>
  );
}
