/* eslint-disable react-hooks/exhaustive-deps */
import { apiKey, apiUrl } from "../config";
import { energyAtom, energyLimitAtom, lastEnergyAtom } from "../atoms/energy";
import { getPhoneType, isMobile } from "../utils/detect";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

import Layout from "../components/Layout";
import LeagueInfo from "../components/LeagueInfo";
import Sidebar from "../components/Sidebar";
import WebApp from "@twa-dev/sdk";
import { getDefaultUser } from "../utils/user";
import { scoreAtom } from "../atoms/score";
import { useNavigationType } from "react-router-dom";
import { vhToPx } from "../utils/number";

const transformDefault = `rotateX(0deg) rotateY(0deg)`;

// TODO: Update energy when the user come back from another page
function Tap() {
  const navigationType = useNavigationType();
  const [animations, setAnimations] = useState([]);
  const [score, setScore] = useAtom(scoreAtom);
  const [transform, setTransform] = useState(transformDefault);
  const user = WebApp.initDataUnsafe.user || getDefaultUser();
  const phone = getPhoneType();

  const [energy, setEnergy] = useAtom(energyAtom);
  const maxEnergy = useAtomValue(energyLimitAtom);
  const setLastUpdatedEnergy = useSetAtom(lastEnergyAtom);

  const handleTouchStart = (event) => {
    if (energy <= 0) return;

    if (phone === "iPhone") {
      if (WebApp.HapticFeedback && WebApp.HapticFeedback.impactOccurred) {
        WebApp.HapticFeedback.impactOccurred("light");
      }
    } else if (phone === "Android") {
      if (navigator.vibrate) {
        navigator.vibrate(10);
      }
    }

    const touches = Array.from(event.touches);

    const touch = touches[0];
    const rect = event.currentTarget.getBoundingClientRect();
    const offsetX = ((touch.clientX - rect.left) / rect.width - 0.5) * 4;
    const offsetY = ((touch.clientY - rect.top) / rect.height - 0.5) * 4;

    const newTransform = `rotateX(${offsetY * -8}deg) rotateY(${
      offsetX * 8
    }deg)`;
    setTransform(newTransform);

    touches.forEach((touch) => {
      const animationId = Date.now() + touch.identifier;
      setAnimations((prevAnimations) => {
        const newAnimation = {
          id: animationId,
          x: touch.clientX,
          y: touch.clientY,
        };

        const updatedAnimations = [...prevAnimations, newAnimation];
        if (updatedAnimations.length > 20) {
          return updatedAnimations.slice(-20);
        }

        return updatedAnimations;
      });
    });

    setScore((prevResult) => prevResult + 1);

    setEnergy((prev) => {
      if (prev > 0) return prev - 1;
      else {
        return prev;
      }
    });

    setLastUpdatedEnergy(new Date().getTime().toString());
  };

  const handleTouchEnd = () => {
    setTransform(transformDefault);
  };

  useEffect(() => {
    const sendData = async () => {
      const dataToSend = {
        telegramId: user.id,
        name: (user.firstName || "") + " " + (user.lastName || ""),
        username: user.username || "",
        score: score,
        device: phone,
      };

      fetch(apiUrl + "init", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Data sent successfully:", data);
        })
        .catch((error) => {
          console.error("Error sending data:", error);
        });
    };

    if (WebApp.initData) {
      sendData();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setEnergy((prevEnergy) => {
        if (prevEnergy < maxEnergy) {
          return prevEnergy + 1;
        }
        return prevEnergy;
      });

      setLastUpdatedEnergy(new Date().getTime().toString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (WebApp.initData && isMobile) {
      if (navigationType === "POP") {
        const currentTime = new Date().getTime();
        const lastUpdated = parseInt(
          JSON.parse(localStorage.getItem("lastUpdatedEnergy") || "0")
        );
        const diffInSeconds = (currentTime - lastUpdated) / 1000;

        if (diffInSeconds > 0) {
          const energyToAdd = Math.floor(Math.round(diffInSeconds));

          const prevEnergy = parseInt(
            JSON.parse(localStorage.getItem("energy") || "0")
          );
          const updatedEnergy = Math.min(prevEnergy + energyToAdd, maxEnergy);

          if (energyToAdd > 0) {
            setEnergy(updatedEnergy);
            setLastUpdatedEnergy(currentTime.toString());
          }
        }
      }
    }
  }, []);

  return (
    <Layout back={false}>
      <div className="bg-gradient"></div>

      <div className="result-display">
        <img src="/dollar.png" />

        <p>{score.toLocaleString()}</p>
      </div>

      {/* <LeagueInfo /> */}

      <div className="sol-logo-container">
        <img
          src="/men-summer.png"
          className="sol-logo"
          alt="Logo"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          style={{ transform: transform }}
        />
      </div>

      {animations.map((animation) => (
        <div
          key={animation.id}
          className="plus-one-animation"
          style={{
            left: animation.x,
            top: animation.y - vhToPx(5),
            position: "absolute",
            zIndex: 2000,
          }}
        >
          <img src="/images/sweat.png" alt="sweat" />
        </div>
      ))}

      <div className="energy">
        <div className="energy-top">
          <img src="/images/energy-icon.png" alt="Energy" />
          <p>{`${energy}/${maxEnergy}`}</p>
        </div>
        <progress value={energy} max={maxEnergy} />
      </div>

      <Sidebar />
    </Layout>
  );
}

export default Tap;
