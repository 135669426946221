import "swiper/css";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { formatNumberCompact, formatWithCommas } from "../utils/number";
import { getCurrentLeagueIndex, leagues } from "../utils/league";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";

import Layout from "../components/Layout";
import { Navigation } from "swiper/modules";
import { leagueAtom } from "../atoms/league";
import { scoreAtom } from "../atoms/score";

export default function League() {
  const score = useAtomValue(scoreAtom);
  const [currentLeague, setCurrentLeague] = useAtom(leagueAtom);
  const currentLeagueIndex = getCurrentLeagueIndex(score);
  const [swiperActive, setSwiperActive] = useState(currentLeagueIndex);

  useEffect(() => {
    const newLeagueIndex = getCurrentLeagueIndex(score);
    const newLeague = leagues[newLeagueIndex];

    if (newLeague && currentLeague && newLeague.id !== currentLeague.id) {
      setCurrentLeague(newLeague);
    }

    setSwiperActive(newLeagueIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        initialSlide={swiperActive}
        className="leagueSwiper"
      >
        {leagues.map((league, index) => (
          <SwiperSlide key={league.name}>
            <div className="league">
              <img src={league.bg} alt={`${league.name} trophy`} />
              <h1 className="text-white">{league.name} League</h1>
              {index === currentLeagueIndex && (
                <div className="league-energy">
                  <div className="league-energy-top">
                    {index < leagues.length - 1 ? (
                      <p>
                        {`${formatWithCommas(score)}/${formatNumberCompact(
                          leagues[index + 1].start
                        )}`}
                      </p>
                    ) : (
                      <p>{formatWithCommas(score - currentLeague.start)}</p>
                    )}
                  </div>
                  <progress
                    value={score - currentLeague.start}
                    max={leagues[index + 1]?.start - currentLeague.start}
                  ></progress>
                </div>
              )}
              {index !== currentLeagueIndex && (
                <p className="text-white">
                  From {formatNumberCompact(league.start)}
                </p>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Layout>
  );
}
