import "./styles/app.scss";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Boosters from "./routes/boosters";
import ErrorPage from "./routes/error-page";
import Invite from "./routes/invite";
import League from "./routes/league";
import React from "react";
import ReactDOM from "react-dom/client";
import Root from "./routes/root";
import Stats from "./routes/stats";
import Tap from "./routes/tap";
import Tasks from "./routes/tasks";
import Trade from "./routes/trade";
import WebApp from "@twa-dev/sdk";
import { appEnv } from "./config";

if (WebApp.initData) {
  WebApp.ready();
  WebApp.setHeaderColor("#000000");
  WebApp.expand();
  WebApp.enableClosingConfirmation();
}

if (!WebApp.initData && appEnv === "production") {
  window.location.href = "https://t.me/SolonaSummerBot/tap";
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/tap",
    element: <Tap />,
  },
  {
    path: "/invite",
    element: <Invite />,
  },
  {
    path: "/tasks",
    element: <Tasks />,
  },
  {
    path: "/boosters",
    element: <Boosters />,
  },
  {
    path: "/trade",
    element: <Trade />,
  },
  {
    path: "/league",
    element: <League />,
  },
  {
    path: "/stats",
    element: <Stats />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
