import { comingSoon } from "../../utils/toast";

export default function MasterTap() {
  return (
    <>
      <img className="modal-icon" src="/images/master-tap-icon.png" />

      <p className="modal-title">MasterTap</p>

      <p className="modal-subtitle">
        Activate to earn x5 coins per tap, for 30 seconds, without using any
        energy.
      </p>

      <p className="modal-charge">
        <img src="/dollar.png" alt="Coins" />
        Free
      </p>

      <button className="btn-get" onClick={comingSoon}>
        Get
      </button>
    </>
  );
}
