import { comingSoon } from "../../utils/toast";

export default function TapBot() {
  return (
    <>
      <img className="modal-icon" src="/images/robot-icon.png" />

      <p className="modal-title">TapBot</p>

      <p className="modal-subtitle">
        TapBot works for you, tapping up to 12 hours when you are away.
      </p>

      <p className="modal-charge">
        <img src="/dollar.png" alt="Coins" />
        200,000
      </p>

      <button className="btn-get" onClick={comingSoon}>
        Get
      </button>
    </>
  );
}
