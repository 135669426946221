import Layout from "../components/Layout";
import { comingSoon } from "../utils/toast";

export default function Trade() {
  return (
    <Layout>
      <div className="page">
        <div className="bg-gradient-small"></div>

        <div className="trade">
          <h1 className="trade-title">Game Summer Trade</h1>

          <div className="trade-coin">
            <div>
              <img src="/dollar.png" className="trade-sol" />
            </div>
            <div className="card card-exchange">
              <img src="/images/trade-icon.png" className="trade-exchange" />
            </div>
            <div>
              <img src="/summer-logo.png" className="trade-cfg" />
            </div>
          </div>

          <div className="trade-subtitle">
            Seamlessly convert your in-game coins into $GAME tokens.
          </div>
        </div>

        <button className="btn-invite" onClick={comingSoon}>
          Trade Now
        </button>
      </div>
    </Layout>
  );
}
