import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <Link to="/invite">
        <div className="sidebar-item">
          {/* <img src="/images/invite-icon.png" alt="Invite" /> */}
          Referral
        </div>
      </Link>

      <Link to="/tasks">
        <div className="sidebar-item">
          {/* <img src="/images/task-icon.png" alt="Tasks" /> */}
          Tasks
        </div>
      </Link>

      {/* <Link to="/boosters">
        <div className="sidebar-item">
          <img src="/images/rocket-icon.png" alt="Boosters" />
          Upgrade
        </div>
      </Link> */}

      <Link to="/trade">
        <div className="sidebar-item">
          {/* <img src="/images/star-icon.png" alt="Trade" /> */}
          Rewards
        </div>
      </Link>

      <Link to="/stats">
        <div className="sidebar-item">
          {/* <img src="/images/stats-icon.png" alt="Stats" /> */}
          Stats
        </div>
      </Link>
    </div>
  );
}
