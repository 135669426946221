import { QRCode } from "react-qrcode-logo";
import logo from "../assets/summer-logo.png";

export default function Desktop() {
  return (
    <div className="desktop-container">
      <p className="text-white">Desktop lacks excitement.</p>
      <p className="text-white">Go mobile for more fun.</p>
      <QRCode
        value="https://t.me/SolonaSummerBot/tap"
        logoImage={logo}
        size={200}
        qrStyle="dots"
        eyeRadius={[
          {
            outer: [10, 10, 0, 10],
            inner: [0, 10, 10, 10],
          },
          [10, 10, 10, 0],
          [10, 0, 10, 10],
        ]}
        eyeColor={["#eb70b6", "#eb70b6", "#eb70b6"]}
        fgColor="#eb70b6"
        bgColor="#000000"
      />
      ,
    </div>
  );
}
